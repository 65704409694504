import axios from "axios";

export const SERVER_URL = "https://odineducation-api.cubettech.in/";
export const PORTAL_API_URL = "https://odineducation-api.cubettech.in/api/";

export const GG_API = "AIzaSyClrULWWMKAxzpwxxdUSyjwpXZKVz9LI40";
export const ReCAPTCHA_API = "6Lc4dGAiAAAAAO2JwPls9u07-UQfArsJbgX5rimv";

export default axios.create({
  baseURL: PORTAL_API_URL,
  headers: {
    Accept: "application/json",
  },
});
